<div class="h-100 color-dark">
  <div class="flex-column gap-5 h-100">
    <button
      pButton
      type="button"
      class="p-button p-button-primary p-button-outlined small icon-only close-button"
      [routerLink]="['/', paths.AUTH, paths.auth.LOGIN]">
      <shared-icon icon="triangle-left" height="1rem" width="1rem" class="fill-dark" />
    </button>
    <div class="text-28 bold-800 text-center color-dark">
      Sign in to your<br />
      Mortgage Monitor
    </div>
    <div class="text-16 text-center line-height-130">
      Your email <b>{{ email }}</b
      >,<br />
      is associated with multiple accounts. <br /><br />
      Which one do you want to login to?
    </div>
    <div class="flex-column gap-3">
      @for (company of companies$ | async; track company.id) {
        <cp-login-company-select-item [company]="company" (click)="selectCompany(company)" />
      }
    </div>
  </div>
</div>
