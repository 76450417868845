import { ENUM_MortgagePartOverpaymentType, ENUM_MortgagePartUnderpaymentType } from '@api-new/common';
import {
  HTTP_CP_Mortgage,
  HTTP_CP_UpdateMortgage_ForIrregularPayments_Request,
  HTTP_CP_UpdateMortgage_ForIrregularPayments_Request_MortgagePartOverpayment,
  HTTP_CP_UpdateMortgage_ForIrregularPayments_Request_MortgagePartUnderpayment,
} from '@api-new/mortgageservice';
import { MortgageIrregularPaymentType } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/model/mortgage-irregular-payments.models';
import { AddIrregularPaymentFormValues } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/utils/formUtils';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils';

export const addIrregularPaymentFormToHTTPRequestAdapters = (
  mortgage: HTTP_CP_Mortgage,
  mortgagePartId: string,
  irregularPaymentType: MortgageIrregularPaymentType,
  addIrregularPaymentFormValues: AddIrregularPaymentFormValues,
): HTTP_CP_UpdateMortgage_ForIrregularPayments_Request => {
  const mortgagePart = mortgage.mortgageParts.find((part) => part.id === mortgagePartId);
  const overpayments: HTTP_CP_UpdateMortgage_ForIrregularPayments_Request_MortgagePartOverpayment[] =
    mortgagePart.mortgagePartOverpayments?.map((overpayment) => ({
      id: overpayment.id,
      mortgagePartOverpaymentType: overpayment.mortgagePartOverpaymentType,
      startDate: overpayment.startDate,
      endDate: overpayment.endDate,
      amount: overpayment.amount,
    })) ?? [];
  const underpayments: HTTP_CP_UpdateMortgage_ForIrregularPayments_Request_MortgagePartUnderpayment[] =
    mortgagePart.mortgagePartUnderpayments?.map((underpayment) => ({
      id: underpayment.id,
      mortgagePartUnderpaymentType: underpayment.mortgagePartUnderpaymentType,
      startDate: underpayment.startDate,
      endDate: underpayment.endDate,
      amount: underpayment.amount,
    })) ?? [];
  return irregularPaymentType === 'overpayment'
    ? {
        mortgageId: mortgage.id,
        mortgagePartId,
        mortgagePartOverpayments: [
          ...overpayments,
          {
            mortgagePartOverpaymentType:
              addIrregularPaymentFormValues.irregularPaymentRecurring === 'recurring'
                ? ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_RECURRING
                : ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_ONE_TIME,
            startDate: dateToISOStringWithoutTimezone(addIrregularPaymentFormValues.fromDate),
            endDate: dateToISOStringWithoutTimezone(addIrregularPaymentFormValues.toDate),
            amount: addIrregularPaymentFormValues.amount,
          },
        ],
        mortgagePartUnderpayments: [...underpayments],
      }
    : {
        mortgageId: mortgage.id,
        mortgagePartId,
        mortgagePartOverpayments: [...overpayments],
        mortgagePartUnderpayments: [
          ...underpayments,
          {
            mortgagePartUnderpaymentType:
              addIrregularPaymentFormValues.irregularPaymentRecurring === 'recurring'
                ? ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_RECURRING
                : ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_ONE_TIME,
            startDate: dateToISOStringWithoutTimezone(addIrregularPaymentFormValues.fromDate),
            endDate: dateToISOStringWithoutTimezone(addIrregularPaymentFormValues.toDate),
            amount: addIrregularPaymentFormValues.amount,
          },
        ],
      };
};
