import { AsyncPipe, NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { environment } from '@environments/environment';
import { FormFieldWrapperComponent } from '@shared-lib/components/forms/form-field-wrapper/form-field-wrapper.component';
import { InputTextComponent } from '@shared-lib/components/forms/input-text/input-text.component';
import { ButtonDirective } from 'primeng/button';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-login-email-input',
  templateUrl: './login-email-input.component.html',
  styleUrls: ['../../auth-theme.scss', 'login-email-input.component.scss'],
  imports: [AsyncPipe, InputTextComponent, ReactiveFormsModule, FormFieldWrapperComponent, ButtonDirective, NgOptimizedImage],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginEmailInputComponent {
  private readonly facade = inject(AuthFacadeService);

  protected readonly loading$ = this.facade.loading$;
  protected readonly noEmailFoundError$ = this.facade.noEmailFoundError$;

  protected signUpUrl = environment.signUpUrl;
  protected emailForm = new FormControl('', [Validators.required, Validators.email]);

  // TODO: Remove this when we have a proper way to detect MAB domain
  protected isMabDomain = false;

  constructor() {
    // TODO: Remove this when we have a proper way to detect MAB domain
    if (location.hostname.includes('mortgageadvicebureau')) {
      this.isMabDomain = true;
    }

    this.noEmailFoundError$.pipe(takeUntilDestroyed()).subscribe(({ noEmailFound }) => {
      if (noEmailFound) {
        this.emailForm.setErrors({ message: 'There is no user account with this e-mail address' });
      }
    });
  }

  login(): void {
    if (this.emailForm.invalid) {
      return;
    }

    this.facade.checkLoginMail(this.emailForm.value);
  }
}
