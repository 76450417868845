<cp-link-container
  [showArrowIcon]="true"
  [contentStyleClass]="(isMobile$ | async) ? 'bg-light' : 'bg-gray-5'">
  <div class="flex-column gap-3 justify-content-start">
    <div class="image-wrapper relative">
      <img class="object-fit-contain object-position-top" [ngSrc]="company.logo" fill />
    </div>
    <div class="text-16 bold">{{ company.tradingName }}</div>
  </div>
</cp-link-container>
