import { Injectable, inject } from '@angular/core';
import { ENUM_MortgageClosedReason } from '@api-new/common';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { addIrregularPaymentFormToHTTPRequestAdapters } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/providers/addIrregularPaymentFormToHTTPRequest.adapters';
import { deleteIrregularPaymentFormToHTTPRequestAdapters } from '@platform/mortgage-detail/components/delete-irregular-payment-dialog/providers/deleteIrregularPaymentFormToHTTPRequest.adapters';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils/date-utils/dateToISOStringWithoutTimezone';
import { ErrorModel } from '@shared/models/app-state.model';
import { getDashboardData, loadInitialPlatformData } from '@shared/store/common.actions';
import {
  addMortgageIrregularPayment,
  addMortgageIrregularPaymentFailure,
  addMortgageIrregularPaymentSuccess,
  closeMortgage,
  closeMortgageSuccess,
  createMortgage,
  createMortgageFailure,
  createMortgageSuccess,
  deleteMortgageIrregularPayment,
  deleteMortgageIrregularPaymentFailure,
  deleteMortgageIrregularPaymentSuccess,
  getMortgages,
  getMortgagesFailure,
  getMortgagesSuccess,
  updateMortgageComparisonSettings,
  updateMortgageComparisonSettingsFailure,
  updateMortgageComparisonSettingsSuccess,
} from '@shared/store/mortgages/mortgages.actions';
import { selectMortgageById } from '@shared/store/mortgages/mortgages.selectors';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class MortgagesEffects {
  private readonly mortgageApiService = inject(MortgageApiService);
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly toastService = inject(ToastService);

  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getMortgages)));

  getMortgages = createEffect(() =>
    this.actions$.pipe(
      ofType(getMortgages),
      switchMap(() => this.mortgageApiService.HTTP_CP_ListMortgages()),
      map((mortgages) => getMortgagesSuccess(mortgages)),
      catchError((error) => of(getMortgagesFailure({ error }))),
    ),
  );

  createMortgage = createEffect(() =>
    this.actions$.pipe(
      ofType(createMortgage),
      switchMap(({ mortgage }) =>
        this.mortgageApiService.HTTP_CP_CreateMortgage(mortgage).pipe(
          map((mortgage) => createMortgageSuccess({ mortgage })),
          catchError((error: ErrorModel) => of(createMortgageFailure({ error }))),
        ),
      ),
    ),
  );

  handleCreateMortgageSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(createMortgageSuccess),
      map(() => getDashboardData()),
    ),
  );

  closeMortgage = createEffect(() =>
    this.actions$.pipe(
      ofType(closeMortgage),
      switchMap(({ mortgageId, closeDated }) =>
        this.mortgageApiService
          .HTTP_CP_CloseMortgage({
            mortgageId,
            reason: ENUM_MortgageClosedReason.MORTGAGE_CLOSED_REASON_PAID_OFF,
            when: dateToISOStringWithoutTimezone(closeDated),
          })
          .pipe(
            map(() => closeMortgageSuccess({ mortgageId })),
            catchError((error) => of(getMortgagesFailure({ error }))),
          ),
      ),
    ),
  );

  updateMortgageSearchCriteria: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(updateMortgageComparisonSettings),
      switchMap(({ comparisonSettings }) =>
        this.mortgageApiService.HTTP_CP_UpdateMortgageComparisonSettings(comparisonSettings).pipe(
          map((newComparisonSettings) => {
            this.toastService.showToast(ToastSeverity.success, 'Search preferences were successfully updated');
            return updateMortgageComparisonSettingsSuccess({ comparisonSettings: newComparisonSettings });
          }),
          catchError((error: ErrorModel) => {
            this.toastService.showToast(ToastSeverity.error, 'Search preferences failed to update');
            return of(updateMortgageComparisonSettingsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  addMortgageIrregularPayment = createEffect(() =>
    this.actions$.pipe(
      ofType(addMortgageIrregularPayment),
      concatLatestFrom(({ mortgageId }) => this.store.select(selectMortgageById(mortgageId))),
      switchMap(([{ mortgagePartId, irregularPaymentType, irregularPayment }, mortgage]) =>
        this.mortgageApiService
          .HTTP_CP_UpdateMortgage_ForIrregularPayments(
            addIrregularPaymentFormToHTTPRequestAdapters(mortgage, mortgagePartId, irregularPaymentType, irregularPayment),
          )
          .pipe(
            map((updatedMortgage) => addMortgageIrregularPaymentSuccess({ mortgage: updatedMortgage })),
            catchError((error: ErrorModel) => of(addMortgageIrregularPaymentFailure({ error }))),
          ),
      ),
    ),
  );

  deleteMortgageIrregularPayment = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteMortgageIrregularPayment),
      concatLatestFrom(({ mortgageId }) => this.store.select(selectMortgageById(mortgageId))),
      switchMap(([{ mortgagePartId, irregularPaymentType, irregularPaymentId }, mortgage]) =>
        this.mortgageApiService
          .HTTP_CP_UpdateMortgage_ForIrregularPayments(
            deleteIrregularPaymentFormToHTTPRequestAdapters(mortgage, mortgagePartId, irregularPaymentType, irregularPaymentId),
          )
          .pipe(
            map((updatedMortgage) => deleteMortgageIrregularPaymentSuccess({ mortgage: updatedMortgage })),
            catchError((error: ErrorModel) => of(deleteMortgageIrregularPaymentFailure({ error }))),
          ),
      ),
    ),
  );
}
