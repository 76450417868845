@if (isLoginFromParamsLoading$ | async) {
  <div class="dashly-loading-container">
    <img alt="loading..." src="../../assets/OLD_images/loading.gif" />
  </div>
} @else {
  <div class="w-100 flex-column align-items-center m-a wrapper relative">
    <div class="header w-100 flex justify-content-center z-index-1">
      <cp-navigation-header-radius-container class="header-mobile pointer-events-none">
        <div class="logo-container">
          <ng-container [ngTemplateOutlet]="brandingLogo" />
        </div>
      </cp-navigation-header-radius-container>
      <div class="header-desktop">
        <ng-container [ngTemplateOutlet]="brandingLogo" />
      </div>
    </div>
    <router-outlet />
  </div>
}

<ng-template #brandingLogo>
  <div class="relative h-100 w-100">
    @if (authPagesBranding$ | async; as branding) {
      <img class="object-fit-contain" [ngSrc]="branding.logoUrl" alt="company-logo" fill />
    } @else {
      <img
        class="object-fit-contain"
        ngSrc="assets/images/mortgage-monitor-blue.png"
        fill
        alt="company-logo" />
    }
  </div>
</ng-template>
