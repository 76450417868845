import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { HTTP_CP_ListSignInCompanies_Response_Company } from '@api-new/authservice';
import { AuthFacadeService } from '@app/auth/auth-facade.service';
import { LoginCompanySelectItemComponent } from '@app/auth/pages/login-company-select/components/login-company-select-item/login-company-select-item.component';
import { paths } from '@platform/paths';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { ButtonDirective } from 'primeng/button';
import { take } from 'rxjs/operators';

@Component({
  selector: 'cp-login-company-select',
  templateUrl: './login-company-select.component.html',
  styleUrls: ['../../auth-theme.scss', 'login-company-select.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, LoginCompanySelectItemComponent, RouterLink, IconComponent, ButtonDirective, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginCompanySelectComponent implements OnInit {
  private readonly authFacade = inject(AuthFacadeService);

  protected email: string;
  protected readonly companies$ = this.authFacade.signInCompanies$;

  protected readonly paths = paths;

  protected selectCompany(company: HTTP_CP_ListSignInCompanies_Response_Company): void {
    this.authFacade.selectCompany(company.id);
  }

  ngOnInit(): void {
    this.authFacade.email$.pipe(take(1)).subscribe((email) => {
      if (!email) {
        this.authFacade.navigateToCheckMail();
      } else {
        this.email = email;
      }
    });
  }
}
