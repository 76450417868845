<div class="h-100 color-dark">
  <div class="flex-column gap-5 h-100">
    <button
      pButton
      type="button"
      class="p-button p-button-primary p-button-outlined small icon-only close-button"
      (click)="goToLoginPage()">
      <shared-icon icon="triangle-left" height="1rem" width="1rem" class="fill-dark" />
    </button>
    @if (deletionState === DeletionState.DELETED) {
      <div class="text-28 bold-800 text-center color-dark">Your account has been deleted</div>
      <div class="text-16 text-center">
        It’s sad you want to leave Dashly. But, as a no obligation platform, you’re free to do
        whatever you want.
      </div>
    } @else {
      <div class="text-28 bold-800 text-center color-dark">Invalid deletion request</div>
      <div class="text-16 text-center">
        The link you provided has either expired or is invalid. Try resending the deletion request.
      </div>
    }

    <div class="text-14 text-center mt-3">
      If we did something wrong, or you’d like to provide us with some feedback, please visit our
      <a class="color-primary bold" href="https://help.dashly.com/kb-tickets/new" target="_blank"
        >support&nbsp;page</a
      >.
    </div>
  </div>
</div>
