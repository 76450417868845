import { Routes } from '@angular/router';
import { RedirectToExternalWebsiteComponent } from '@app/auth/components/redirect-to-external-website/redirect-to-external-website.component';
import { DeletedComponent } from '@app/auth/pages/deleted/deleted.component';
import { LoginCompanySelectComponent } from '@app/auth/pages/login-company-select/login-company-select.component';
import { LoginEmailInputComponent } from '@app/auth/pages/login-email-input/login-email-input.component';
import { LoginVerificationCodeComponent } from '@app/auth/pages/login-verification-code/login-verification-code.component';
import { TermsAndConditionsAgreementComponent } from '@app/auth/pages/terms-and-conditions-agreement/terms-and-conditions-agreement.component';
import { environment } from '@environments/environment';
import { paths } from '@platform/paths';
import { PreloadCheckpoints } from '@shared/enums/preload-checkpoints.enum';
import { AuthGuard } from '@shared/guards/auth.guard';
import { AuthComponent } from './auth';

export const routes: Routes = [
  {
    path: paths.PLATFORM,
    canActivate: [AuthGuard],
    loadChildren: () => import('./platform/platform.module').then((m) => m.PlatformModule),
    data: { preload: true, preloadCheckpoint: PreloadCheckpoints.PLATFORM },
  },
  {
    path: 'referral',
    redirectTo: 'auth/register',
  },
  {
    path: paths.AUTH,
    redirectTo: 'auth/login',
  },
  {
    path: 'register',
    redirectTo: 'auth/register',
  },
  {
    path: paths.AUTH,
    component: AuthComponent,
    children: [
      {
        path: 'register',
        data: {
          redirectToExternalWebsiteData: {
            websiteUrl: environment.signUpUrl,
            queryParamsRenameMap: { referralCode: 'referral-code' },
          },
        },
        component: RedirectToExternalWebsiteComponent,
      },
      {
        path: 'delete-account',
        component: DeletedComponent,
      },
      {
        path: 'login',
        component: LoginEmailInputComponent,
      },
      {
        path: paths.auth.ACCOUNT_SELECT,
        component: LoginCompanySelectComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsAgreementComponent,
      },
      {
        path: 'login-verification',
        component: LoginVerificationCodeComponent,
      },
    ],
  },
  {
    path: paths.UNSUBSCRIBE,
    loadComponent: async () => (await import('./landing/unsubscribe/unsubscribe.component')).UnsubscribeComponent,
  },
  {
    path: paths.AGGREGATOR_LANDING_PAGE,
    canActivate: [AuthGuard],
    loadComponent: async () => (await import('./aggregator-landing-page/aggregator-landing-page.component')).AggregatorLandingPageComponent,
    loadChildren: () => import('./aggregator-landing-page/aggregator-landing-page.routes').then((r) => r.aggregatorLandingPageRoutes),
  },
  {
    path: '**',
    redirectTo: 'platform',
  },
];
