import { Injectable } from '@angular/core';
import { HTTP_AP_CloseMortgage_Request } from '@api-new/mortgageservice/HTTP_AP_CloseMortgage_Request';
import { HTTP_AP_CompanyMortgageComparisonSettings } from '@api-new/mortgageservice/HTTP_AP_CompanyMortgageComparisonSettings';
import { HTTP_AP_CompanyPreCompletionComparisonSettings } from '@api-new/mortgageservice/HTTP_AP_CompanyPreCompletionComparisonSettings';
import { HTTP_AP_CreateLenderGroup_Request } from '@api-new/mortgageservice/HTTP_AP_CreateLenderGroup_Request';
import { HTTP_AP_CreateMortgage_Request } from '@api-new/mortgageservice/HTTP_AP_CreateMortgage_Request';
import { HTTP_AP_DeleteLenderGroup_Request } from '@api-new/mortgageservice/HTTP_AP_DeleteLenderGroup_Request';
import { HTTP_AP_ExportCompanyMortgages_Request } from '@api-new/mortgageservice/HTTP_AP_ExportCompanyMortgages_Request';
import { HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Request } from '@api-new/mortgageservice/HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Request';
import { HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Response } from '@api-new/mortgageservice/HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Response';
import { HTTP_AP_GetCompanyMortgageComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_GetCompanyMortgageComparisonSettings_Request';
import { HTTP_AP_GetCompanyPreCompletionComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_GetCompanyPreCompletionComparisonSettings_Request';
import { HTTP_AP_GetLenderDefaultErcs_ForLenderDetail_Request } from '@api-new/mortgageservice/HTTP_AP_GetLenderDefaultErcs_ForLenderDetail_Request';
import { HTTP_AP_GetLenderGroup_Request } from '@api-new/mortgageservice/HTTP_AP_GetLenderGroup_Request';
import { HTTP_AP_GetLender_ForLenderDetail_Request } from '@api-new/mortgageservice/HTTP_AP_GetLender_ForLenderDetail_Request';
import { HTTP_AP_GetLender_ForLenderDetail_Response } from '@api-new/mortgageservice/HTTP_AP_GetLender_ForLenderDetail_Response';
import { HTTP_AP_GetLender_Request } from '@api-new/mortgageservice/HTTP_AP_GetLender_Request';
import { HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Request } from '@api-new/mortgageservice/HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Request';
import { HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Response } from '@api-new/mortgageservice/HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Response';
import { HTTP_AP_GetMortgageProduct_Request } from '@api-new/mortgageservice/HTTP_AP_GetMortgageProduct_Request';
import { HTTP_AP_GetMortgageProduct_Response } from '@api-new/mortgageservice/HTTP_AP_GetMortgageProduct_Response';
import { HTTP_AP_GetMortgage_ForComparison_Request } from '@api-new/mortgageservice/HTTP_AP_GetMortgage_ForComparison_Request';
import { HTTP_AP_GetMortgage_ForComparison_Response } from '@api-new/mortgageservice/HTTP_AP_GetMortgage_ForComparison_Response';
import { HTTP_AP_GetMortgage_Request } from '@api-new/mortgageservice/HTTP_AP_GetMortgage_Request';
import { HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Request } from '@api-new/mortgageservice/HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Request';
import { HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Response } from '@api-new/mortgageservice/HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Response';
import { HTTP_AP_GetPreCompletionComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletionComparisonSettings_Request';
import { HTTP_AP_GetPreCompletionComparisonSettings_Response } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletionComparisonSettings_Response';
import { HTTP_AP_GetPreCompletion_ForComparison_Request } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletion_ForComparison_Request';
import { HTTP_AP_GetPreCompletion_ForComparison_Response } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletion_ForComparison_Response';
import { HTTP_AP_GetPreCompletion_ForDetail_Request } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletion_ForDetail_Request';
import { HTTP_AP_GetPreCompletion_ForDetail_Response } from '@api-new/mortgageservice/HTTP_AP_GetPreCompletion_ForDetail_Response';
import { HTTP_AP_GetSmartSearch_ForComparison_Request } from '@api-new/mortgageservice/HTTP_AP_GetSmartSearch_ForComparison_Request';
import { HTTP_AP_GetSmartSearch_ForComparison_Response } from '@api-new/mortgageservice/HTTP_AP_GetSmartSearch_ForComparison_Response';
import { HTTP_AP_Lender } from '@api-new/mortgageservice/HTTP_AP_Lender';
import { HTTP_AP_LenderDefaultErcs_ForLenderDetail } from '@api-new/mortgageservice/HTTP_AP_LenderDefaultErcs_ForLenderDetail';
import { HTTP_AP_LenderGroup } from '@api-new/mortgageservice/HTTP_AP_LenderGroup';
import { HTTP_AP_ListDeals_ForComparison_Request } from '@api-new/mortgageservice/HTTP_AP_ListDeals_ForComparison_Request';
import { HTTP_AP_ListDeals_ForComparison_Response } from '@api-new/mortgageservice/HTTP_AP_ListDeals_ForComparison_Response';
import { HTTP_AP_ListLenderCriteria_ForLenderDetail_Request } from '@api-new/mortgageservice/HTTP_AP_ListLenderCriteria_ForLenderDetail_Request';
import { HTTP_AP_ListLenderCriteria_ForLenderDetail_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenderCriteria_ForLenderDetail_Response';
import { HTTP_AP_ListLenderGroups_ForSelect_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenderGroups_ForSelect_Response';
import { HTTP_AP_ListLenderGroups_ForTable_Request } from '@api-new/mortgageservice/HTTP_AP_ListLenderGroups_ForTable_Request';
import { HTTP_AP_ListLenderGroups_ForTable_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenderGroups_ForTable_Response';
import { HTTP_AP_ListLenderSvrs_ForLenderDetail_Request } from '@api-new/mortgageservice/HTTP_AP_ListLenderSvrs_ForLenderDetail_Request';
import { HTTP_AP_ListLenderSvrs_ForLenderDetail_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenderSvrs_ForLenderDetail_Response';
import { HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Request } from '@api-new/mortgageservice/HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Request';
import { HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Response';
import { HTTP_AP_ListLenders_ForTable_Request } from '@api-new/mortgageservice/HTTP_AP_ListLenders_ForTable_Request';
import { HTTP_AP_ListLenders_ForTable_Response } from '@api-new/mortgageservice/HTTP_AP_ListLenders_ForTable_Response';
import { HTTP_AP_ListMortgageBorrowers_Request } from '@api-new/mortgageservice/HTTP_AP_ListMortgageBorrowers_Request';
import { HTTP_AP_ListMortgageBorrowers_Response } from '@api-new/mortgageservice/HTTP_AP_ListMortgageBorrowers_Response';
import { HTTP_AP_ListMortgageProducts_ForTable_Request } from '@api-new/mortgageservice/HTTP_AP_ListMortgageProducts_ForTable_Request';
import { HTTP_AP_ListMortgageProducts_ForTable_Response } from '@api-new/mortgageservice/HTTP_AP_ListMortgageProducts_ForTable_Response';
import { HTTP_AP_ListMortgages_ForTable_Request } from '@api-new/mortgageservice/HTTP_AP_ListMortgages_ForTable_Request';
import { HTTP_AP_ListMortgages_ForTable_Response } from '@api-new/mortgageservice/HTTP_AP_ListMortgages_ForTable_Response';
import { HTTP_AP_ListPreCompletions_ForTable_Request } from '@api-new/mortgageservice/HTTP_AP_ListPreCompletions_ForTable_Request';
import { HTTP_AP_ListPreCompletions_ForTable_Response } from '@api-new/mortgageservice/HTTP_AP_ListPreCompletions_ForTable_Response';
import { HTTP_AP_MatchMortgageProducts_Request } from '@api-new/mortgageservice/HTTP_AP_MatchMortgageProducts_Request';
import { HTTP_AP_MatchMortgageProducts_Response } from '@api-new/mortgageservice/HTTP_AP_MatchMortgageProducts_Response';
import { HTTP_AP_Mortgage } from '@api-new/mortgageservice/HTTP_AP_Mortgage';
import { HTTP_AP_MortgageComparisonSettings } from '@api-new/mortgageservice/HTTP_AP_MortgageComparisonSettings';
import { HTTP_AP_ResumeMortgageCaseCreation_Request } from '@api-new/mortgageservice/HTTP_AP_ResumeMortgageCaseCreation_Request';
import { HTTP_AP_SetCompanyExcludedLenders_Request } from '@api-new/mortgageservice/HTTP_AP_SetCompanyExcludedLenders_Request';
import { HTTP_AP_SetMortgageVerification_Request } from '@api-new/mortgageservice/HTTP_AP_SetMortgageVerification_Request';
import { HTTP_AP_SetNetworkExcludedLenders_Request } from '@api-new/mortgageservice/HTTP_AP_SetNetworkExcludedLenders_Request';
import { HTTP_AP_UnsetMortgageVerification_Request } from '@api-new/mortgageservice/HTTP_AP_UnsetMortgageVerification_Request';
import { HTTP_AP_UpdateCompanyMortgageComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateCompanyMortgageComparisonSettings_Request';
import { HTTP_AP_UpdateCompanyPreCompletionComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateCompanyPreCompletionComparisonSettings_Request';
import { HTTP_AP_UpdateLenderDefaultErcs_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateLenderDefaultErcs_Request';
import { HTTP_AP_UpdateLenderGroup_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateLenderGroup_Request';
import { HTTP_AP_UpdateLenderLogo_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateLenderLogo_Request';
import { HTTP_AP_UpdateLenderSvrInterestRates_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateLenderSvrInterestRates_Request';
import { HTTP_AP_UpdateLenderSvrInterestRates_Response } from '@api-new/mortgageservice/HTTP_AP_UpdateLenderSvrInterestRates_Response';
import { HTTP_AP_UpdateMortgageComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateMortgageComparisonSettings_Request';
import { HTTP_AP_UpdateMortgage_ForIrregularPayments_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateMortgage_ForIrregularPayments_Request';
import { HTTP_AP_UpdateMortgage_Request } from '@api-new/mortgageservice/HTTP_AP_UpdateMortgage_Request';
import { HTTP_AP_UpdatePreCompletionComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_AP_UpdatePreCompletionComparisonSettings_Request';
import { HTTP_AP_UpdatePreCompletionComparisonSettings_Response } from '@api-new/mortgageservice/HTTP_AP_UpdatePreCompletionComparisonSettings_Response';
import { HTTP_CP_CloseMortgage_Request } from '@api-new/mortgageservice/HTTP_CP_CloseMortgage_Request';
import { HTTP_CP_CreateMortgage_Request } from '@api-new/mortgageservice/HTTP_CP_CreateMortgage_Request';
import { HTTP_CP_CreateSmartSearch_Request } from '@api-new/mortgageservice/HTTP_CP_CreateSmartSearch_Request';
import { HTTP_CP_DeleteSmartSearch_Request } from '@api-new/mortgageservice/HTTP_CP_DeleteSmartSearch_Request';
import { HTTP_CP_GetDashboardData_Response } from '@api-new/mortgageservice/HTTP_CP_GetDashboardData_Response';
import { HTTP_CP_GetMortgageAmortizationTable_Request } from '@api-new/mortgageservice/HTTP_CP_GetMortgageAmortizationTable_Request';
import { HTTP_CP_GetMortgageAmortizationTable_Response } from '@api-new/mortgageservice/HTTP_CP_GetMortgageAmortizationTable_Response';
import { HTTP_CP_GetMortgageDealAmortizationTable_Request } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealAmortizationTable_Request';
import { HTTP_CP_GetMortgageDealAmortizationTable_Response } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealAmortizationTable_Response';
import { HTTP_CP_GetMortgageDealComparison_Request } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealComparison_Request';
import { HTTP_CP_GetMortgageDealComparison_Response } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealComparison_Response';
import { HTTP_CP_GetMortgageDealRepresentativeExample_Request } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealRepresentativeExample_Request';
import { HTTP_CP_GetMortgageDealRepresentativeExample_Response } from '@api-new/mortgageservice/HTTP_CP_GetMortgageDealRepresentativeExample_Response';
import { HTTP_CP_GetSmartSearchDealRepresentativeExample_Request } from '@api-new/mortgageservice/HTTP_CP_GetSmartSearchDealRepresentativeExample_Request';
import { HTTP_CP_GetSmartSearchDealRepresentativeExample_Response } from '@api-new/mortgageservice/HTTP_CP_GetSmartSearchDealRepresentativeExample_Response';
import { HTTP_CP_ListMortgageDeals_Request } from '@api-new/mortgageservice/HTTP_CP_ListMortgageDeals_Request';
import { HTTP_CP_ListMortgageDeals_Response } from '@api-new/mortgageservice/HTTP_CP_ListMortgageDeals_Response';
import { HTTP_CP_ListMortgages_Response } from '@api-new/mortgageservice/HTTP_CP_ListMortgages_Response';
import { HTTP_CP_ListSmartSearchDeals_Request } from '@api-new/mortgageservice/HTTP_CP_ListSmartSearchDeals_Request';
import { HTTP_CP_ListSmartSearchDeals_Response } from '@api-new/mortgageservice/HTTP_CP_ListSmartSearchDeals_Response';
import { HTTP_CP_ListSmartSearches_Response } from '@api-new/mortgageservice/HTTP_CP_ListSmartSearches_Response';
import { HTTP_CP_MatchMortgageProducts_Request } from '@api-new/mortgageservice/HTTP_CP_MatchMortgageProducts_Request';
import { HTTP_CP_MatchMortgageProducts_Response } from '@api-new/mortgageservice/HTTP_CP_MatchMortgageProducts_Response';
import { HTTP_CP_Mortgage } from '@api-new/mortgageservice/HTTP_CP_Mortgage';
import { HTTP_CP_MortgageComparisonSettings } from '@api-new/mortgageservice/HTTP_CP_MortgageComparisonSettings';
import { HTTP_CP_SmartSearch } from '@api-new/mortgageservice/HTTP_CP_SmartSearch';
import { HTTP_CP_UpdateMortgageComparisonSettings_Request } from '@api-new/mortgageservice/HTTP_CP_UpdateMortgageComparisonSettings_Request';
import { HTTP_CP_UpdateMortgage_ForIrregularPayments_Request } from '@api-new/mortgageservice/HTTP_CP_UpdateMortgage_ForIrregularPayments_Request';
import { HTTP_ULC_MatchMortgageProduct_Request } from '@api-new/mortgageservice/HTTP_ULC_MatchMortgageProduct_Request';
import { HTTP_ULC_MatchMortgageProduct_Response } from '@api-new/mortgageservice/HTTP_ULC_MatchMortgageProduct_Response';
import { HTTP_X_CalculateInterestRate_ForFullRepayment_Request } from '@api-new/mortgageservice/HTTP_X_CalculateInterestRate_ForFullRepayment_Request';
import { HTTP_X_CalculateInterestRate_ForFullRepayment_Response } from '@api-new/mortgageservice/HTTP_X_CalculateInterestRate_ForFullRepayment_Response';
import { HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Request } from '@api-new/mortgageservice/HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Request';
import { HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Response } from '@api-new/mortgageservice/HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Response';
import { HTTP_X_CalculateMonthlyPayments_Request } from '@api-new/mortgageservice/HTTP_X_CalculateMonthlyPayments_Request';
import { HTTP_X_CalculateMonthlyPayments_Response } from '@api-new/mortgageservice/HTTP_X_CalculateMonthlyPayments_Response';
import { HTTP_X_CalculatePayMortgageFasterSavings_Request } from '@api-new/mortgageservice/HTTP_X_CalculatePayMortgageFasterSavings_Request';
import { HTTP_X_CalculatePayMortgageFasterSavings_Response } from '@api-new/mortgageservice/HTTP_X_CalculatePayMortgageFasterSavings_Response';
import { HTTP_X_CompareMortgageProducts_Request } from '@api-new/mortgageservice/HTTP_X_CompareMortgageProducts_Request';
import { HTTP_X_CompareMortgageProducts_Response } from '@api-new/mortgageservice/HTTP_X_CompareMortgageProducts_Response';
import { HTTP_X_GetMortgageProductRepresentativeExample_Request } from '@api-new/mortgageservice/HTTP_X_GetMortgageProductRepresentativeExample_Request';
import { HTTP_X_GetMortgageProductRepresentativeExample_Response } from '@api-new/mortgageservice/HTTP_X_GetMortgageProductRepresentativeExample_Response';
import { HTTP_X_ListLenders_Request } from '@api-new/mortgageservice/HTTP_X_ListLenders_Request';
import { HTTP_X_ListLenders_Response } from '@api-new/mortgageservice/HTTP_X_ListLenders_Response';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class MortgageApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_CloseMortgage(request: HTTP_AP_CloseMortgage_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages/${request.mortgageId}/Close`, request);
  }

  HTTP_AP_CreateMortgage(request: HTTP_AP_CreateMortgage_Request): Observable<HTTP_AP_Mortgage> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages`, request);
  }

  HTTP_AP_CreateLenderGroup(request: HTTP_AP_CreateLenderGroup_Request): Observable<HTTP_AP_LenderGroup> {
    return this.httpClient.post(`/mortgage-service/ap/lender-groups`, request);
  }

  HTTP_AP_DeleteLenderGroup(request: HTTP_AP_DeleteLenderGroup_Request): Observable<undefined> {
    return this.httpClient.delete(`/mortgage-service/ap/lender-groups`, { params: request });
  }

  HTTP_AP_ExportCompanyMortgages(request: HTTP_AP_ExportCompanyMortgages_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages/all/ExportCompanyMortgages`, request);
  }

  HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders(
    request: HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Request,
  ): Observable<HTTP_AP_GetCompanyMortgageComparisonSettings_ForLenders_Response> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/company-mortgage-comparison-settings/${request.companyId}/ForLenders`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetCompanyMortgageComparisonSettings(
    request: HTTP_AP_GetCompanyMortgageComparisonSettings_Request,
  ): Observable<HTTP_AP_CompanyMortgageComparisonSettings> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/company-mortgage-comparison-settings/${request.companyId}`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetCompanyPreCompletionComparisonSettings(
    request: HTTP_AP_GetCompanyPreCompletionComparisonSettings_Request,
  ): Observable<HTTP_AP_CompanyPreCompletionComparisonSettings> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/company-pre-completion-comparison-settings/${request.companyId}`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetLender(request: HTTP_AP_GetLender_Request): Observable<HTTP_AP_Lender> {
    const { id, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lenders/id/${request.id}`, { params: updatedRequest });
  }

  HTTP_AP_GetLender_ForLenderDetail(
    request: HTTP_AP_GetLender_ForLenderDetail_Request,
  ): Observable<HTTP_AP_GetLender_ForLenderDetail_Response> {
    const { id, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lenders/id/${request.id}/ForLenderDetail`, { params: updatedRequest });
  }

  HTTP_AP_GetLenderDefaultErcs_ForLenderDetail(
    request: HTTP_AP_GetLenderDefaultErcs_ForLenderDetail_Request,
  ): Observable<HTTP_AP_LenderDefaultErcs_ForLenderDetail> {
    const { lenderId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lenders/id/${request.lenderId}/default-ercs`, { params: updatedRequest });
  }

  HTTP_AP_GetLenderGroup(request: HTTP_AP_GetLenderGroup_Request): Observable<HTTP_AP_LenderGroup> {
    const { lenderGroupId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lenders-group/${request.lenderGroupId}`, { params: updatedRequest });
  }

  HTTP_AP_GetMortgage(request: HTTP_AP_GetMortgage_Request): Observable<HTTP_AP_Mortgage> {
    const { mortgageId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/mortgages/${request.mortgageId}`, { params: updatedRequest });
  }

  HTTP_AP_GetMortgage_ForComparison(
    request: HTTP_AP_GetMortgage_ForComparison_Request,
  ): Observable<HTTP_AP_GetMortgage_ForComparison_Response> {
    const { mortgageId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/mortgages/${request.mortgageId}/ForComparison`, { params: updatedRequest });
  }

  HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection(
    request: HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Request,
  ): Observable<HTTP_AP_GetMortgageComparisonSettings_ForAdvisorDealRejection_Response> {
    return this.httpClient.get(`/mortgage-service/ap/user-preferences/one/ForAdvisorDealRejection`, { params: request });
  }

  HTTP_AP_GetMortgageProduct(request: HTTP_AP_GetMortgageProduct_Request): Observable<HTTP_AP_GetMortgageProduct_Response> {
    const { mortgageProductId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/mortgage-products/${request.mortgageProductId}`, { params: updatedRequest });
  }

  HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders(
    request: HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Request,
  ): Observable<HTTP_AP_GetNetworkMortgageComparisonSettings_ForLenders_Response> {
    const { networkId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/network-mortgage-comparison-settings/${request.networkId}/ForLenders`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetPreCompletion_ForComparison(
    request: HTTP_AP_GetPreCompletion_ForComparison_Request,
  ): Observable<HTTP_AP_GetPreCompletion_ForComparison_Response> {
    const { preCompletionId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/pre-completions/${request.preCompletionId}/ForComparison`, { params: updatedRequest });
  }

  HTTP_AP_GetPreCompletion_ForDetail(
    request: HTTP_AP_GetPreCompletion_ForDetail_Request,
  ): Observable<HTTP_AP_GetPreCompletion_ForDetail_Response> {
    const { preCompletionId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/pre-completions/${request.preCompletionId}/ForDetail`, { params: updatedRequest });
  }

  HTTP_AP_GetPreCompletionComparisonSettings(
    request: HTTP_AP_GetPreCompletionComparisonSettings_Request,
  ): Observable<HTTP_AP_GetPreCompletionComparisonSettings_Response> {
    const { preCompletionId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/pre-completions/${request.preCompletionId}/comparison-settings`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetSmartSearch_ForComparison(
    request: HTTP_AP_GetSmartSearch_ForComparison_Request,
  ): Observable<HTTP_AP_GetSmartSearch_ForComparison_Response> {
    const { smartSearchId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/smart-searches/${request.smartSearchId}/ForComparison`, { params: updatedRequest });
  }

  HTTP_AP_ListDeals_ForComparison(request: HTTP_AP_ListDeals_ForComparison_Request): Observable<HTTP_AP_ListDeals_ForComparison_Response> {
    return this.httpClient.get(`/mortgage-service/ap/deals/all/ForComparison`, { params: request });
  }

  HTTP_AP_ListLenderCriteria_ForLenderDetail(
    request: HTTP_AP_ListLenderCriteria_ForLenderDetail_Request,
  ): Observable<HTTP_AP_ListLenderCriteria_ForLenderDetail_Response> {
    const { lenderId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lender/${request.lenderId}/lender-criteria/all/ForLenderDetail`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_ListLenderGroups_ForSelect(request?: undefined): Observable<HTTP_AP_ListLenderGroups_ForSelect_Response> {
    return this.httpClient.get(`/mortgage-service/ap/lender-groups/all/ForSelect`, { params: request });
  }

  HTTP_AP_ListLenderGroups_ForTable(
    request: HTTP_AP_ListLenderGroups_ForTable_Request,
  ): Observable<HTTP_AP_ListLenderGroups_ForTable_Response> {
    return this.httpClient.get(`/mortgage-service/ap/lender-groups/all/ForTable`, { params: request });
  }

  HTTP_AP_ListLenderSvrs_ForLenderDetail(
    request: HTTP_AP_ListLenderSvrs_ForLenderDetail_Request,
  ): Observable<HTTP_AP_ListLenderSvrs_ForLenderDetail_Response> {
    const { lenderId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/lender/${request.lenderId}/lender-svrs/all/ForLenderDetail`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail(
    request: HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Request,
  ): Observable<HTTP_AP_ListLenderSvrs_ForSvrUpdateDetail_Response> {
    return this.httpClient.post(`/mortgage-service/ap/lender/${request.lenderId}/lender-svrs/all/ForSvrUpdateDetail`, request);
  }

  HTTP_AP_ListLenders_ForTable(request: HTTP_AP_ListLenders_ForTable_Request): Observable<HTTP_AP_ListLenders_ForTable_Response> {
    return this.httpClient.get(`/mortgage-service/ap/lenders`, { params: request });
  }

  HTTP_AP_ListMortgageBorrowers(request: HTTP_AP_ListMortgageBorrowers_Request): Observable<HTTP_AP_ListMortgageBorrowers_Response> {
    const { mortgageId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/ap/mortgages/${request.mortgageId}/mortgage-borrowers`, { params: updatedRequest });
  }

  HTTP_AP_ListMortgages_ForTable(request: HTTP_AP_ListMortgages_ForTable_Request): Observable<HTTP_AP_ListMortgages_ForTable_Response> {
    return this.httpClient.get(`/mortgage-service/ap/mortgages/all/ForTable`, { params: request });
  }

  HTTP_AP_ListMortgageProducts_ForTable(
    request: HTTP_AP_ListMortgageProducts_ForTable_Request,
  ): Observable<HTTP_AP_ListMortgageProducts_ForTable_Response> {
    return this.httpClient.get(`/mortgage-service/ap/mortgage-products/all/ForTable`, { params: request });
  }

  HTTP_AP_ListPreCompletions_ForTable(
    request: HTTP_AP_ListPreCompletions_ForTable_Request,
  ): Observable<HTTP_AP_ListPreCompletions_ForTable_Response> {
    return this.httpClient.get(`/mortgage-service/ap/pre-completions/all/ForTable`, { params: request });
  }

  HTTP_AP_MatchMortgageProducts(request: HTTP_AP_MatchMortgageProducts_Request): Observable<HTTP_AP_MatchMortgageProducts_Response> {
    return this.httpClient.post(`/mortgage-service/ap/mortgage-products/all/MatchForAdvisorPortalCreateMortgage`, request);
  }

  HTTP_AP_ResumeMortgageCaseCreation(request: HTTP_AP_ResumeMortgageCaseCreation_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages/${request.mortgageId}/ResumeMortgageCaseCreation`, request);
  }

  HTTP_AP_SetCompanyExcludedLenders(request: HTTP_AP_SetCompanyExcludedLenders_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/companies/${request.companyId}/excluded-lenders`, request);
  }

  HTTP_AP_SetMortgageVerification(request: HTTP_AP_SetMortgageVerification_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages/${request.mortgageId}/SetVerification`, request);
  }

  HTTP_AP_SetNetworkExcludedLenders(request: HTTP_AP_SetNetworkExcludedLenders_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/networks/${request.networkId}/excluded-lenders`, request);
  }

  HTTP_AP_UnsetMortgageVerification(request: HTTP_AP_UnsetMortgageVerification_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/ap/mortgages/${request.mortgageId}/UnsetVerification`, request);
  }

  HTTP_AP_UpdateCompanyMortgageComparisonSettings(
    request: HTTP_AP_UpdateCompanyMortgageComparisonSettings_Request,
  ): Observable<HTTP_AP_CompanyMortgageComparisonSettings> {
    return this.httpClient.put(`/mortgage-service/ap/company-mortgage-comparison-settings/${request.companyId}`, request);
  }

  HTTP_AP_UpdateCompanyPreCompletionComparisonSettings(
    request: HTTP_AP_UpdateCompanyPreCompletionComparisonSettings_Request,
  ): Observable<HTTP_AP_CompanyPreCompletionComparisonSettings> {
    return this.httpClient.put(`/mortgage-service/ap/company-pre-completion-comparison-settings/${request.companyId}`, request);
  }

  HTTP_AP_UpdateLenderDefaultErcs(request: HTTP_AP_UpdateLenderDefaultErcs_Request): Observable<HTTP_AP_LenderDefaultErcs_ForLenderDetail> {
    return this.httpClient.put(`/mortgage-service/ap/lenders/id/${request.lenderId}/default-ercs`, request);
  }

  HTTP_AP_UpdateLenderSvrInterestRates(
    request: HTTP_AP_UpdateLenderSvrInterestRates_Request,
  ): Observable<HTTP_AP_UpdateLenderSvrInterestRates_Response> {
    return this.httpClient.post(`/mortgage-service/ap/lender-svrs/UpdateInterestRates`, request);
  }

  HTTP_AP_UpdateLenderGroup(request: HTTP_AP_UpdateLenderGroup_Request): Observable<HTTP_AP_LenderGroup> {
    return this.httpClient.patch(`/mortgage-service/ap/lender-groups/${request.lenderGroupId}`, request);
  }

  HTTP_AP_UpdateLenderLogo(request: HTTP_AP_UpdateLenderLogo_Request): Observable<HTTP_AP_Lender> {
    return this.httpClient.patch(`/mortgage-service/ap/lenders/${request.lenderId}/logo`, request);
  }

  HTTP_AP_UpdateMortgage(request: HTTP_AP_UpdateMortgage_Request): Observable<HTTP_AP_Mortgage> {
    return this.httpClient.put(`/mortgage-service/ap/mortgages/id/${request.id}`, request);
  }

  HTTP_AP_UpdateMortgage_ForIrregularPayments(request: HTTP_AP_UpdateMortgage_ForIrregularPayments_Request): Observable<HTTP_AP_Mortgage> {
    return this.httpClient.put(`/mortgage-service/ap/mortgages/${request.mortgageId}/ForIrregularPayments`, request);
  }

  HTTP_AP_UpdateMortgageComparisonSettings(
    request: HTTP_AP_UpdateMortgageComparisonSettings_Request,
  ): Observable<HTTP_AP_MortgageComparisonSettings> {
    return this.httpClient.put(`/mortgage-service/ap/mortgages/${request.mortgageId}/mortgage-comparison-settings`, request);
  }

  HTTP_AP_UpdatePreCompletionComparisonSettings(
    request: HTTP_AP_UpdatePreCompletionComparisonSettings_Request,
  ): Observable<HTTP_AP_UpdatePreCompletionComparisonSettings_Response> {
    return this.httpClient.put(
      `/mortgage-service/ap/pre-completions/${request.preCompletionId}/pre-completion-comparison-settings`,
      request,
    );
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_CloseMortgage(request: HTTP_CP_CloseMortgage_Request): Observable<undefined> {
    return this.httpClient.post(`/mortgage-service/cp/mortgages/${request.mortgageId}/Close`, request);
  }

  HTTP_CP_CreateMortgage(request: HTTP_CP_CreateMortgage_Request): Observable<HTTP_CP_Mortgage> {
    return this.httpClient.post(`/mortgage-service/cp/mortgages`, request);
  }

  HTTP_CP_CreateSmartSearch(request: HTTP_CP_CreateSmartSearch_Request): Observable<HTTP_CP_SmartSearch> {
    return this.httpClient.post(`/mortgage-service/cp/smart-searches`, request);
  }

  HTTP_CP_DeleteSmartSearch(request: HTTP_CP_DeleteSmartSearch_Request): Observable<undefined> {
    const { id, ...updatedRequest } = request;
    return this.httpClient.delete(`/mortgage-service/cp/smart-searches/${request.id}`, { params: updatedRequest });
  }

  HTTP_CP_GetDashboardData(request?: undefined): Observable<HTTP_CP_GetDashboardData_Response> {
    return this.httpClient.get(`/mortgage-service/cp/DashboardData`, { params: request });
  }

  HTTP_CP_GetMortgageAmortizationTable(
    request: HTTP_CP_GetMortgageAmortizationTable_Request,
  ): Observable<HTTP_CP_GetMortgageAmortizationTable_Response> {
    const { mortgageId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/cp/mortgages/id/${request.mortgageId}/ComputeAmortizationTable`, {
      params: updatedRequest,
    });
  }

  HTTP_CP_GetMortgageDealAmortizationTable(
    request: HTTP_CP_GetMortgageDealAmortizationTable_Request,
  ): Observable<HTTP_CP_GetMortgageDealAmortizationTable_Response> {
    return this.httpClient.get(`/mortgage-service/cp/mortgages/deals/amortization/ForDealDetail`, { params: request });
  }

  HTTP_CP_GetMortgageDealComparison(
    request: HTTP_CP_GetMortgageDealComparison_Request,
  ): Observable<HTTP_CP_GetMortgageDealComparison_Response> {
    return this.httpClient.get(`/mortgage-service/cp/mortgages/deals/compare`, { params: request });
  }

  HTTP_CP_GetMortgageDealRepresentativeExample(
    request: HTTP_CP_GetMortgageDealRepresentativeExample_Request,
  ): Observable<HTTP_CP_GetMortgageDealRepresentativeExample_Response> {
    return this.httpClient.get(`/mortgage-service/cp/mortgages/deals/representative-example`, { params: request });
  }

  HTTP_CP_GetSmartSearchDealRepresentativeExample(
    request: HTTP_CP_GetSmartSearchDealRepresentativeExample_Request,
  ): Observable<HTTP_CP_GetSmartSearchDealRepresentativeExample_Response> {
    return this.httpClient.get(`/mortgage-service/cp/smart-searches/deals/representative-example`, { params: request });
  }

  HTTP_CP_UpdateMortgage_ForIrregularPayments(request: HTTP_CP_UpdateMortgage_ForIrregularPayments_Request): Observable<HTTP_CP_Mortgage> {
    return this.httpClient.put(`/mortgage-service/cp/mortgages/${request.mortgageId}/ForIrregularPayments`, request);
  }

  HTTP_CP_ListMortgageDeals(request: HTTP_CP_ListMortgageDeals_Request): Observable<HTTP_CP_ListMortgageDeals_Response> {
    const { mortgageId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/cp/mortgages/${request.mortgageId}/deals`, { params: updatedRequest });
  }

  HTTP_CP_ListMortgages(request?: undefined): Observable<HTTP_CP_ListMortgages_Response> {
    return this.httpClient.get(`/mortgage-service/cp/mortgages`, { params: request });
  }

  HTTP_CP_ListSmartSearchDeals(request: HTTP_CP_ListSmartSearchDeals_Request): Observable<HTTP_CP_ListSmartSearchDeals_Response> {
    const { smartSearchId, ...updatedRequest } = request;
    return this.httpClient.get(`/mortgage-service/cp/smart-searches/${request.smartSearchId}/deals`, { params: updatedRequest });
  }

  HTTP_CP_ListSmartSearches(request?: undefined): Observable<HTTP_CP_ListSmartSearches_Response> {
    return this.httpClient.get(`/mortgage-service/cp/smart-searches`, { params: request });
  }

  HTTP_CP_MatchMortgageProducts(request: HTTP_CP_MatchMortgageProducts_Request): Observable<HTTP_CP_MatchMortgageProducts_Response> {
    return this.httpClient.post(`/mortgage-service/cp/mortgage-products/all/MatchForClientPortalCreateMortgage`, request);
  }

  HTTP_CP_UpdateMortgageComparisonSettings(
    request: HTTP_CP_UpdateMortgageComparisonSettings_Request,
  ): Observable<HTTP_CP_MortgageComparisonSettings> {
    return this.httpClient.put(`/mortgage-service/cp/mortgage-comparison-settings/${request.mortgageId}`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_ULC_MatchMortgageProduct(request: HTTP_ULC_MatchMortgageProduct_Request): Observable<HTTP_ULC_MatchMortgageProduct_Response> {
    return this.httpClient.post(`/mortgage-service/ulc/mortgage-products/all/Match`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_X_CalculateInterestRate_ForFullRepayment(
    request: HTTP_X_CalculateInterestRate_ForFullRepayment_Request,
  ): Observable<HTTP_X_CalculateInterestRate_ForFullRepayment_Response> {
    return this.httpClient.post(`/mortgage-service/x/calculate/interest-rate/ForFullRepayment`, request);
  }

  HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment(
    request: HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Request,
  ): Observable<HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment_Response> {
    return this.httpClient.post(`/mortgage-service/x/calculate/interest-rate/ForInterestOnlyRepayment`, request);
  }

  HTTP_X_CalculateMonthlyPayments(request: HTTP_X_CalculateMonthlyPayments_Request): Observable<HTTP_X_CalculateMonthlyPayments_Response> {
    return this.httpClient.post(`/mortgage-service/x/calculate/payment`, request);
  }

  HTTP_X_CalculatePayMortgageFasterSavings(
    request: HTTP_X_CalculatePayMortgageFasterSavings_Request,
  ): Observable<HTTP_X_CalculatePayMortgageFasterSavings_Response> {
    return this.httpClient.post(`/mortgage-service/x/calculate/pay-mortgage-faster-savings`, request);
  }

  HTTP_X_CompareMortgageProducts(request: HTTP_X_CompareMortgageProducts_Request): Observable<HTTP_X_CompareMortgageProducts_Response> {
    return this.httpClient.post(`/mortgage-service/x/mortgage-products/all/Compare`, request);
  }

  HTTP_X_GetMortgageProductRepresentativeExample(
    request: HTTP_X_GetMortgageProductRepresentativeExample_Request,
  ): Observable<HTTP_X_GetMortgageProductRepresentativeExample_Response> {
    return this.httpClient.post(`/mortgage-service/x/mortgage-products/one/representative-example`, request);
  }

  HTTP_X_ListLenders(request: HTTP_X_ListLenders_Request): Observable<HTTP_X_ListLenders_Response> {
    return this.httpClient.get(`/mortgage-service/x/lenders`, { params: request });
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // TODO 2022/11/04 experimental endpoint for testing asynchronous refresh of views

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
