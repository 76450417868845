import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { RouterLink } from '@angular/router';
import { VerificationInputComponent } from '@app/auth/pages/login-verification-code/components/verification-input/verification-input.component';
import { paths } from '@platform/paths';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { ApiErrorCode } from '@shared/enums/api-error-code.enum';
import { SignInType } from '@shared/store/auth/auth.reducer';
import { ButtonDirective } from 'primeng/button';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-login-verification-code',
  templateUrl: './login-verification-code.component.html',
  styleUrls: ['../../auth-theme.scss', 'login-verification-code.component.scss'],
  imports: [AsyncPipe, VerificationInputComponent, ReactiveFormsModule, ButtonDirective, IconComponent, RouterLink],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginVerificationCodeComponent implements OnInit {
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly facade = inject(AuthFacadeService);
  private readonly destroyRef = inject(DestroyRef);

  protected readonly SignInType = SignInType;
  protected readonly ApiErrorCode = ApiErrorCode;
  protected readonly paths = paths;

  lastTimeVerificationCodeSent$: Observable<Date> = this.facade.lastTimeVerificationCodeSent$;
  loading$: Observable<boolean> = this.facade.loading$;
  lastSignInType$: Observable<SignInType> = this.facade.lasSignInType$;
  authError$: Observable<string> = this.facade.authError$;
  isSendingVerificationCode$: Observable<boolean> = this.facade.isSendingVerificationCode$;
  isInvalidCodeError$: Observable<boolean> = this.facade.isInvalidCodeError$;
  verificationCodeFromFailedMagicLink$: Observable<string> = this.facade.verificationCodeFromFailedMagicLink$;
  isExpiredCodeError$: Observable<boolean> = this.facade.isExpiredCodeError$;

  verificationCodeForm: FormGroup<{ verificationCode: FormControl<string> }>;
  email: string;
  intervalForTimeLeftTillResendEnabled: number;
  timeLeftTillResendEnabled = 0;

  ngOnInit(): void {
    this.intervalForTimeLeftTillResendEnabled = this.getSecondCounterToZero();
    this.verificationCodeForm = new FormGroup({
      verificationCode: new FormControl<string>(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });

    this.verificationCodeFromFailedMagicLink$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((code: string) => {
      if (code) {
        this.verificationCodeForm.controls.verificationCode.setValue(code, { emitEvent: false });
      }
    });
    this.lastTimeVerificationCodeSent$.pipe(filter(Boolean), takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.timeLeftTillResendEnabled = 30;
    });
    this.facade.email$.pipe(take(1)).subscribe((email) => {
      if (!email) {
        this.facade.navigateToCheckMail();
      } else {
        this.email = email;
      }
    });

    this.verificationCodeForm.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.verificationCodeForm.valid) {
        this.getListOfSignInCompanies();
      }
    });
  }

  getListOfSignInCompanies(): void {
    if (this.verificationCodeForm.valid) {
      this.facade.getListOfSignInCompanies(this.verificationCodeForm.value.verificationCode);
    }
  }

  resendCode(): void {
    this.isSendingVerificationCode$.pipe(take(1)).subscribe((isSendingVerificationCode) => {
      if (this.timeLeftTillResendEnabled === 0 && !isSendingVerificationCode) {
        this.facade.sendVerificationCode(this.email);
      }
    });
  }

  private getSecondCounterToZero(): number {
    return setInterval(() => {
      if (this.timeLeftTillResendEnabled > 0) {
        this.timeLeftTillResendEnabled--;
        this.changeDetectorRef.detectChanges();
      }
    }, 1000);
  }

  clearLastSentVerificationCode(): void {
    this.facade.clearLastSentVerificationCode();
  }
}
