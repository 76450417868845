import { NgClass } from '@angular/common';
import { Component, Input, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { SkeletonModule } from 'primeng/skeleton';
import { TooltipModule } from 'primeng/tooltip';
import { AbstractControlToFormControlPipe } from '../../../pipes/abstract-control-to-form-control.pipe';
import { IconComponent } from '../../icon/icon.component';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'shared-form-field-wrapper',
  imports: [
    ValidationMessageComponent,
    ReactiveFormsModule,
    AbstractControlToFormControlPipe,
    NgClass,
    SkeletonModule,
    IconComponent,
    TooltipModule,
  ],
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  providers: [FormGroupDirective],
})
export class FormFieldWrapperComponent {
  @Input() label: string | undefined;
  @Input() labelTooltip: string | undefined;
  @Input() contentTooltip: string | undefined;
  @Input() reactNoSubmitted = false;
  @Input() name: string | undefined;
  @Input() needsEllipsis: boolean | undefined = false;
  @Input() isInline: boolean | undefined = false;
  @Input() isLoading: boolean | undefined = false;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // trick it into thinking control has a valueAccessor, we don't need it because child has
      this.ngControl.valueAccessor = {
        writeValue(): void {
          // do nothing
        },
        registerOnChange(): void {
          // do nothing
        },
        registerOnTouched(): void {
          // do nothing
        },
      };
    }
  }

  protected readonly Validators = Validators;
}
