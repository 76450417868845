import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { paths } from '@platform/paths';
import { IconComponent } from '@shared-lib/components/icon/icon.component';

@Component({
  selector: 'cp-link-container',
  imports: [IconComponent, RouterLink],
  templateUrl: './link-container.component.html',
  styleUrl: './link-container.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LinkContainerComponent {
  @Input() headline: string;
  @Input() routerLink: string | string[];
  @Input() contentStyleClass: string;
  @Input() isActive: boolean;
  @Input() showArrowIcon: boolean;

  protected readonly paths = paths;
}
