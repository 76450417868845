import { HTTP_CP_Mortgage, HTTP_CP_UpdateMortgage_ForIrregularPayments_Request } from '@api-new/mortgageservice';
import { MortgageIrregularPaymentType } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/model/mortgage-irregular-payments.models';

export const deleteIrregularPaymentFormToHTTPRequestAdapters = (
  mortgage: HTTP_CP_Mortgage,
  mortgagePartId: string,
  irregularPaymentType: MortgageIrregularPaymentType,
  irregularPaymentId: string,
): HTTP_CP_UpdateMortgage_ForIrregularPayments_Request => {
  const mortgagePart = mortgage.mortgageParts.find((part) => part.id === mortgagePartId);
  return irregularPaymentType === 'overpayment'
    ? {
        mortgageId: mortgage.id,
        mortgagePartId,
        mortgagePartOverpayments: mortgagePart.mortgagePartOverpayments.filter((overpayment) => overpayment.id !== irregularPaymentId),
        mortgagePartUnderpayments: mortgagePart.mortgagePartUnderpayments,
      }
    : {
        mortgageId: mortgage.id,
        mortgagePartId,
        mortgagePartOverpayments: mortgagePart.mortgagePartOverpayments,
        mortgagePartUnderpayments: mortgagePart.mortgagePartUnderpayments.filter((underpayment) => underpayment.id !== irregularPaymentId),
      };
};
