@if (this.control?.invalid && this.control?.touched) {
  <div>
    @if (errors?.required) {
      <div #required>
        <ng-content select="[required-message]" />
      </div>
      @if (!required.hasChildNodes()) {
        Field is required.
      }
    }
    @if (errors?.min) {
      <div #min>
        <ng-content select="[min-message]" />
      </div>
      @if (!min.hasChildNodes()) {
        Minimum is {{ errors.min.min }}
      }
    }
    @if (errors?.max) {
      <div #max>
        <ng-content select="[max-message]" />
      </div>
      @if (!max.hasChildNodes()) {
        Maximum value is {{ errors.max.max }}
      }
    }
    @if (errors?.minlength) {
      <div #minlength>
        <ng-content select="[minlength-message]" />
      </div>
      @if (!minlength.hasChildNodes()) {
        Minimum length is {{ errors.minlength.requiredLength }} characters
      }
    }
    @if (errors?.maxlength) {
      <div #maxlength>
        <ng-content select="[maxlength-message]" />
      </div>
      @if (!maxlength.hasChildNodes()) {
        Maximum length is {{ errors.maxlength.requiredLength }} characters
      }
    }
    @if (errors?.email) {
      <div #email>
        <ng-content select="[email-message]" />
      </div>
      @if (!email.hasChildNodes()) {
        Please insert a valid email address
      }
    }
    @if (errors?.pattern) {
      <div #pattern>
        <ng-content select="[pattern-message]" />
      </div>
      @if (!pattern.hasChildNodes()) {
        Please insert a valid value
      }
    }
    @if (errors?.invalidFileType) {
      <div #invalidFileType>
        <ng-content select="[invalid-file-type-message]" />
      </div>
      @if (!invalidFileType.hasChildNodes()) {
        Unsupported file format.
      }
    }
    @if (errors?.maxFileSizeExceeded) {
      <div #maxFileSizeExceeded>
        <ng-content select="[invalid-file-size-message]" />
      </div>
      @if (!maxFileSizeExceeded.hasChildNodes()) {
        Max file size exceeded.
      }
    }
    @if (errors?.message) {
      <ng-container>
        {{ errors?.message }}
      </ng-container>
    }
  </div>
}

@if (customError) {
  <ng-content #custom />
}
