@if (headline) {
  <div class="mx-3 mb-2 uppercase text-10 bold">{{ headline }}</div>
}
<div
  class="layout-fr-auto p-4 border-radius-3 gap-3 pointer wrapper"
  [class]="contentStyleClass"
  [class.active]="isActive"
  [routerLink]="routerLink">
  <ng-content />
  @if (showArrowIcon) {
    <shared-icon
      class="fill-primary align-self-start"
      icon="triangle-right"
      width="1rem"
      height="1rem" />
  }
  <div #bottom class="span-column-2" [style.display]="bottom.hasChildNodes() ? '' : 'none'">
    <ng-content select="[bottom]" />
  </div>
</div>
