<div class="h-100 color-dark flex-column w-100">
  <div class="image-wrapper relative w-100">
    <img
      class="background-image"
      ngSrc="assets/images/landing-pages-background.png"
      fill
      priority />
  </div>
  <div class="flex-column align-items-center p-5">
    <div class="text-28 bold-800 color-dark mb-5">
      Welcome to your <br />
      Mortgage Monitor
    </div>
    <span class="text-16 mb-5">Please enter your email address to continue</span>
    <form class="flex-column gap-3 w-100">
      <div class="landing-responsive-row">
        <shared-form-field-wrapper class="w-100" label="E-mail" [formControl]="emailForm">
          <shared-input-text [formControl]="emailForm" name="login-email" type="email" />
        </shared-form-field-wrapper>
      </div>
      <div class="login-button-container">
        <button
          pButton
          class="p-button p-button-primary w-100 centered"
          (click)="login()"
          [disabled]="loading$ | async"
          [loading]="loading$ | async">
          Continue
        </button>
      </div>
    </form>
  </div>

  <div class="text-14 bold-800 color-primary text-center mb-5 mt-auto">
    <a [href]="signUpUrl" class="form-change-button">Need an account? Create an account</a>
  </div>
</div>
