import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'cp-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  imports: [NgClass],
})
export class CheckboxComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() disabled = false;
  @Input() checked: boolean;
  @Output() checkedChange = new EventEmitter<boolean>();

  @ViewChild('tooltipOrigin') tooltipOrigin: CdkOverlayOrigin;

  changeCallback: (value: boolean) => void = () => null;
  touchCallback: () => void = () => null;

  checkboxClicked(): void {
    if (this.disabled) {
      return;
    }
    this.checked = !this.checked;
    this.checkedChange.emit(this.checked);
    this.changeCallback(this.checked);
    this.touchCallback();
  }

  writeValue(value: boolean): void {
    this.checked = value;
  }

  registerOnChange(fn: (value: boolean) => void): void {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchCallback = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
