import {
  HTTP_CP_CreateMortgage_Request,
  HTTP_CP_ListMortgages_Response,
  HTTP_CP_Mortgage,
  HTTP_CP_MortgageComparisonSettings,
  HTTP_CP_UpdateMortgageComparisonSettings_Request,
} from '@api-new/mortgageservice';
import { createAction, props } from '@ngrx/store';
import { MortgageIrregularPaymentType } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/model/mortgage-irregular-payments.models';
import { AddIrregularPaymentFormValues } from '@platform/mortgage-detail/components/add-irregular-payment-dialog/utils/formUtils';
import { ErrorModel } from '@shared/models/app-state.model';

export const getMortgages = createAction('[MORTGAGES] Get Mortgages');
export const getMortgagesSuccess = createAction('[MORTGAGES] Get Mortgages Success', props<HTTP_CP_ListMortgages_Response>());
export const getMortgagesFailure = createAction('[MORTGAGES] Get Mortgages Failure', props<{ error: ErrorModel }>());

export const createMortgage = createAction('[MORTGAGES] Create Mortgage', props<{ mortgage: HTTP_CP_CreateMortgage_Request }>());
export const createMortgageSuccess = createAction('[MORTGAGES] Create Mortgage Success', props<{ mortgage: HTTP_CP_Mortgage }>());
export const createMortgageFailure = createAction('[MORTGAGES] Create Mortgage Failure', props<{ error: ErrorModel }>());

export const closeMortgage = createAction('[MORTGAGES] Close Mortgage', props<{ mortgageId: string; closeDated: Date }>());
export const closeMortgageSuccess = createAction('[MORTGAGES] Close Mortgage Success', props<{ mortgageId: string }>());
export const closeMortgageFailure = createAction('[MORTGAGES] Close Mortgage Failure', props<{ error: ErrorModel }>());

export const updateMortgageComparisonSettings = createAction(
  '[MORTGAGES] Update Mortgage Search Criteria',
  props<{ comparisonSettings: HTTP_CP_UpdateMortgageComparisonSettings_Request }>(),
);
export const updateMortgageComparisonSettingsSuccess = createAction(
  '[MORTGAGES] Update Mortgage Search Criteria Success',
  props<{ comparisonSettings: HTTP_CP_MortgageComparisonSettings }>(),
);
export const updateMortgageComparisonSettingsFailure = createAction(
  '[MORTGAGES] Update Mortgage Search Criteria Failure',
  props<{ error: ErrorModel }>(),
);

export const addMortgageIrregularPayment = createAction(
  '[MORTGAGES] Add Mortgage Irregular Payment',
  props<{
    mortgageId: string;
    mortgagePartId: string;
    irregularPaymentType: MortgageIrregularPaymentType;
    irregularPayment: AddIrregularPaymentFormValues;
  }>(),
);
export const addMortgageIrregularPaymentSuccess = createAction(
  '[MORTGAGES] Add Mortgage Irregular Payment Success',
  props<{ mortgage: HTTP_CP_Mortgage }>(),
);
export const addMortgageIrregularPaymentFailure = createAction(
  '[MORTGAGES] Add Mortgage Irregular Payment Failure',
  props<{ error: ErrorModel }>(),
);

export const deleteMortgageIrregularPayment = createAction(
  '[MORTGAGES] Delete Mortgage Irregular Payment',
  props<{
    mortgageId: string;
    mortgagePartId: string;
    irregularPaymentType: MortgageIrregularPaymentType;
    irregularPaymentId: string;
  }>(),
);
export const deleteMortgageIrregularPaymentSuccess = createAction(
  '[MORTGAGES] Delete Mortgage Irregular Payment Success',
  props<{ mortgage: HTTP_CP_Mortgage }>(),
);
export const deleteMortgageIrregularPaymentFailure = createAction(
  '[MORTGAGES] Delete Mortgage Irregular Payment Failure',
  props<{ error: ErrorModel }>(),
);
