import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '@shared/store/auth/auth.state';
import {
  agreeToTermsAndConditions,
  agreeToTermsAndConditionsFailure,
  agreeToTermsAndConditionsSuccess,
  askForTermsAndConditions,
  authError,
  checkLoginMail,
  checkLoginMailFailure,
  checkLoginMailSuccess,
  clearLastSignInCode,
  getAuthPagesBranding,
  getAuthPagesBrandingFailure,
  getAuthPagesBrandingSuccess,
  getListOfSignInCompanies,
  getListOfSignInCompaniesSuccess,
  login,
  loginFailure,
  loginMagicLink,
  loginMagicLinkFailure,
  loginMagicLinkSuccess,
  loginSuccess,
  loginWithToken,
  loginWithTokenSuccess,
  passwordReset,
  passwordResetFailure,
  passwordResetSuccess,
  resetPasswordResetDone,
  sendVerificationCode,
  sendVerificationCodeFailure,
  sendVerificationCodeSuccess,
  setRedirectUrlAfterLoginAction,
} from './auth.action';

export enum SignInType {
  VERIFICATION_CODE = 'VERIFICATION_CODE',
  LONG_MAGIC_LINK = 'LONG_MAGIC_LINK',
  SHORT_MAGIC_LINK = 'SHORT_MAGIC_LINK',
}

const authInitialState: AuthState = {
  auth: {
    authErrorCode: null,
    email: null,
    lastSignInCode: null,
    lastSignInType: null,
    lastTimeVerificationCodeSent: null,
    isSendingVerificationCode: false,
    redirectUrl: null,
    doesEmailExist: null,
    verificationCode: null,
    signInCompanies: [],
    branding: null,
    isLoginFromParamsLoading: false,
  },
  loadingActions: {
    isLoadingBranding: false,
    isLoadingSignInCompanies: false,
  },
  errors: null,
  loading: false,
};

const reducer = createReducer(
  authInitialState,

  on(passwordReset, checkLoginMail, (state) => ({
    ...state,
    loading: true,
  })),

  on(loginWithToken, (state, { redirectUrl }) => ({
    ...state,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: true,
      redirectUrl,
    },
  })),

  on(loginMagicLink, (state, { email, verificationCode, redirectUrl }) => ({
    ...state,
    loading: true,
    auth: {
      ...state.auth,
      email,
      lastSignInType: verificationCode.length > 6 ? SignInType.LONG_MAGIC_LINK : SignInType.SHORT_MAGIC_LINK,
      redirectUrl,
    },
  })),

  on(login, (state) => ({
    ...state,
    loading: true,
    auth: {
      ...state.auth,
      lastSignInType: SignInType.VERIFICATION_CODE,
    },
  })),

  on(
    loginWithTokenSuccess,
    (state): AuthState => ({
      ...state,
      loading: false,
      errors: null,
      auth: {
        ...state.auth,
        isLoginFromParamsLoading: false,
      },
    }),
  ),

  on(passwordResetFailure, checkLoginMailFailure, loginFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: false,
    },
  })),

  on(loginMagicLinkFailure, (state, { error, code }) => ({
    ...state,
    loading: false,
    errors: error,
    auth: {
      ...state.auth,
      lastSignInCode: code.length > 6 ? null : code,
      isLoginFromParamsLoading: false,
      magicLinkFailed: true,
    },
  })),

  on(clearLastSignInCode, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      lastSignInCode: null,
    },
  })),

  on(checkLoginMailSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(agreeToTermsAndConditions, (state) => ({
    ...state,
    loading: true,
  })),

  on(agreeToTermsAndConditionsSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(agreeToTermsAndConditionsFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(loginSuccess, loginMagicLinkSuccess, (state, { link }) => ({
    ...state,
    loading: false,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: false,
      redirectUrl: link ?? state.auth.redirectUrl,
    },
  })),

  on(
    askForTermsAndConditions,
    (state): AuthState => ({
      ...state,
      loading: false,
      auth: {
        ...state.auth,
        isLoginFromParamsLoading: false,
      },
    }),
  ),

  on(passwordResetSuccess, (state) => ({
    ...state,
    loading: false,
    auth: {
      ...state.auth,
      isResetRequestDone: true,
    },
  })),

  on(resetPasswordResetDone, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isResetRequestDone: false,
    },
  })),

  on(authError, (state, { authErrorCode }) => ({
    ...state,
    auth: {
      ...state.auth,
      authErrorCode,
    },
  })),

  on(
    checkLoginMail,
    loginMagicLink,
    (state, { email }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        email,
        doesEmailExist: null,
      },
    }),
  ),

  on(
    checkLoginMailSuccess,
    (state, { doesEmailExist }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        doesEmailExist: doesEmailExist ?? false,
        lastSignInType: null,
        lastSignInCode: null,
      },
    }),
  ),

  on(sendVerificationCode, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: true,
      authErrorCode: null,
      lastSignInType: null,
    },
  })),

  on(sendVerificationCodeSuccess, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: false,
      lastTimeVerificationCodeSent: new Date(),
    },
  })),

  on(sendVerificationCodeFailure, (state, { error }) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: false,
    },
    error,
  })),

  on(setRedirectUrlAfterLoginAction, (state, { redirectUrl }) => ({
    ...state,
    auth: {
      ...state.auth,
      redirectUrl,
    },
  })),

  on(
    getAuthPagesBranding,
    (state): AuthState => ({
      ...state,
      loadingActions: {
        ...state.loadingActions,
        isLoadingBranding: true,
      },
    }),
  ),

  on(
    getAuthPagesBrandingSuccess,
    (state, { branding }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        branding,
      },
      loadingActions: {
        ...state.loadingActions,
        isLoadingBranding: false,
      },
    }),
  ),

  on(getAuthPagesBrandingFailure, (state, { error }) => ({
    ...state,
    loadingActions: {
      ...state.loadingActions,
      isLoadingBranding: false,
    },
    error,
  })),

  on(
    getListOfSignInCompanies,
    (state, { verificationCode }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        verificationCode,
      },
      loadingActions: {
        ...state.loadingActions,
        isLoadingSignInCompanies: true,
      },
    }),
  ),

  on(
    getListOfSignInCompaniesSuccess,
    (state, { companies }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        signInCompanies: companies,
      },
      loadingActions: {
        ...state.loadingActions,
        isLoadingSignInCompanies: false,
      },
    }),
  ),
);

export function authReducer(state: AuthState = authInitialState, action: Action): AuthState {
  return reducer(state, action);
}
