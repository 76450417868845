import { AsyncPipe, NgOptimizedImage, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Params, RouterOutlet } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { NavigationHeaderRadiusContainerComponent } from '@shared/components/navigation-header-radius-container/navigation-header-radius-container.component';
import { AuthService } from '@shared/services/auth.service';
import { getAuthPagesBrandingFailure, getAuthPagesBrandingSuccess } from '@shared/store/auth/auth.action';
import { AuthFacadeService } from './auth-facade.service';

@Component({
  selector: 'cp-auth',
  templateUrl: './auth.component.html',
  providers: [AuthFacadeService],
  imports: [AsyncPipe, RouterOutlet, NavigationHeaderRadiusContainerComponent, NgTemplateOutlet, NgOptimizedImage],
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  private readonly facade = inject(AuthFacadeService);
  private readonly route = inject(ActivatedRoute);
  private readonly authService = inject(AuthService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly actions$ = inject(Actions);

  readonly authPagesBranding$ = this.facade.authPagesBranding$;
  readonly isLoginFromParamsLoading$ = this.facade.isLoginFromParamsOrBrandingLoading$;

  ngOnInit(): void {
    this.getAuthPagesBranding(`${location.protocol}//${location.hostname}`);

    this.actions$
      .pipe(takeUntilDestroyed(this.destroyRef), ofType(getAuthPagesBrandingSuccess, getAuthPagesBrandingFailure))
      .subscribe(() => {
        const params = this.route.snapshot.queryParams;
        if (params['token']) {
          this.loginWithToken(params);
        } else if (params['auth']) {
          this.loginWithMagicLink(params);
        }
      });
  }

  private getAuthPagesBranding(domain: string): void {
    this.facade.getAuthPagesBranding(domain);
  }

  private loginWithMagicLink(params: Params): void {
    const decodedParams = new URLSearchParams(atob(params['auth']));
    const email = decodedParams.get('email');
    const code = decodedParams.get('code');
    const redirectUrl = decodedParams.get('redirectUrl');

    if (code && email) {
      this.facade.loginMagicLink(email, code, redirectUrl);
    }
  }

  private loginWithToken(params: Params): void {
    this.authService.logout();
    this.authService.setImpersonated();
    this.facade.loginWithToken(params['token'], params['link']);
  }
}
