export const paths = {
  PLATFORM: 'platform',
  UNSUBSCRIBE: 'unsubscribe',
  AUTH: 'auth',
  AGGREGATOR_LANDING_PAGE: 'a',
  auth: {
    REGISTER: 'register',
    LOGIN: 'login',
    LOGIN_VERIFICATION: 'login-verification',
    ACCOUNT_SELECT: 'account-select',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
  },
  aggregatorLandingPage: {
    CLEARSCORE: 'clearscore',
    clearscore: {
      BOOK_A_CALLBACK: 'book-a-callback',
      CALLBACK_REQUESTED: 'callback-requested',
      UNSUBSCRIBE: 'unsubscribe',
    },
  },
  platform: {
    DASHBOARD: 'dashboard',
    PROPERTIES: 'properties',
    SMART_SEARCH: 'smart-search',
    FIRST_TIME: 'first-time',
    MORTGAGES: 'mortgages',
    MORTGAGE_DETAIL: 'mortgage-detail',
    MORTGAGE_CASE_DETAIL: 'mortgage-case-detail',
    HELP_CASE: 'enquiries',
    DOCUMENTS: 'documents',
    PROPERTY: 'property',
    BETTER_DEAL_OVERALL: 'better-deal-overall',
    BETTER_DEAL_COMPARISON: 'better-deal-comparison',
    END_OF_FIX_STRATEGY: 'end-of-fix-strategy',
    OPPORTUNITY_BETTER_DEAL_DETAIL: 'opportunity-better-deal-detail',
    PROFILE: 'profile',
    ERROR: 'error',
    EPC: 'epc',
    mortgages: {
      ADD: 'add',
      SEARCH_RESULTS: 'search-results',
    },
    property: {
      ADD: 'add',
      add: {
        ADDRESS: 'address',
      },
    },
    profile: {
      SEARCH_PREFERENCES: 'search-preferences',
      NOTIFICATIONS_SETTING: 'notification-settings',
      ADVISOR: 'advisor',
      CREDIT_REPORT: 'credit-and-expenditure',
      INCOME: 'income-and-affordability',
      ACTIONS: 'actions',
    },
  },
};
