import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthFacadeService } from '@app/auth/auth-facade.service';
import { UserActionsRequest } from '@app/auth/models/user-action-request.model';
import { ActionsSubject, Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { AppState } from '@shared/models/app-state.model';
import { deleteClientUser, deleteClientUserFailure, deleteClientUserSuccess } from '@shared/store/client-user/client-user.actions';
import { ButtonDirective } from 'primeng/button';
import { filter, take } from 'rxjs/operators';

enum DeletionState {
  DELETED,
  FAILED,
}

@Component({
  selector: 'cp-deleted',
  imports: [ButtonDirective, IconComponent],
  templateUrl: './deleted.component.html',
  styleUrls: ['../../auth-theme.scss', 'deleted.component.scss'],
})
export class DeletedComponent {
  readonly DeletionState = DeletionState;

  protected deletionState: DeletionState;
  protected queryParams: UserActionsRequest;

  protected readonly paths = paths;

  constructor(
    private readonly store: Store<AppState>,
    private readonly dispatcher: ActionsSubject,
    private readonly route: ActivatedRoute,
    private readonly facade: AuthFacadeService,
  ) {
    this.route.queryParams.subscribe((queryParams: UserActionsRequest) => {
      this.queryParams = queryParams;
      this.store.dispatch(deleteClientUser({ code: this.queryParams.code }));
      this.dispatcher
        .pipe(
          filter((action) => action.type === deleteClientUserSuccess.type || action.type === deleteClientUserFailure.type),
          take(1),
        )
        .subscribe((action) => {
          if (action.type === deleteClientUserSuccess.type) {
            this.deletionState = DeletionState.DELETED;
          } else {
            this.deletionState = DeletionState.FAILED;
          }
        });
    });
  }

  goToLoginPage(): void {
    this.facade.goToLoginPage();
  }
}
