import { Injectable } from '@angular/core';
import { HTTP_AP_ImpersonateClientUser_Request } from '@api-new/authservice/HTTP_AP_ImpersonateClientUser_Request';
import { HTTP_AP_ImpersonateClientUser_Response } from '@api-new/authservice/HTTP_AP_ImpersonateClientUser_Response';
import { HTTP_AP_ProvideAdvisorUserResetPasswordCode_Request } from '@api-new/authservice/HTTP_AP_ProvideAdvisorUserResetPasswordCode_Request';
import { HTTP_AP_ResetPassword_WithCode_Request } from '@api-new/authservice/HTTP_AP_ResetPassword_WithCode_Request';
import { HTTP_AP_SignIn_WithPassword_Request } from '@api-new/authservice/HTTP_AP_SignIn_WithPassword_Request';
import { HTTP_AP_SignIn_WithPassword_Response } from '@api-new/authservice/HTTP_AP_SignIn_WithPassword_Response';
import { HTTP_AP_SignIn_WithProvider_Authorize_Request } from '@api-new/authservice/HTTP_AP_SignIn_WithProvider_Authorize_Request';
import { HTTP_AP_SignIn_WithProvider_Authorize_Response } from '@api-new/authservice/HTTP_AP_SignIn_WithProvider_Authorize_Response';
import { HTTP_AP_SignIn_WithProvider_Initialize_Request } from '@api-new/authservice/HTTP_AP_SignIn_WithProvider_Initialize_Request';
import { HTTP_AP_SignIn_WithProvider_Initialize_Response } from '@api-new/authservice/HTTP_AP_SignIn_WithProvider_Initialize_Response';
import { HTTP_CP_DeleteClientUserAccessToken_Request } from '@api-new/authservice/HTTP_CP_DeleteClientUserAccessToken_Request';
import { HTTP_CP_DeleteClientUser_WithCode_Request } from '@api-new/authservice/HTTP_CP_DeleteClientUser_WithCode_Request';
import { HTTP_CP_ListClientUserAccessTokens_Response } from '@api-new/authservice/HTTP_CP_ListClientUserAccessTokens_Response';
import { HTTP_CP_ListSignInCompanies_Request } from '@api-new/authservice/HTTP_CP_ListSignInCompanies_Request';
import { HTTP_CP_ListSignInCompanies_Response } from '@api-new/authservice/HTTP_CP_ListSignInCompanies_Response';
import { HTTP_CP_ProvideClientUserSignInCode_Request } from '@api-new/authservice/HTTP_CP_ProvideClientUserSignInCode_Request';
import { HTTP_CP_SignIn_WithCode_Request } from '@api-new/authservice/HTTP_CP_SignIn_WithCode_Request';
import { HTTP_CP_SignIn_WithCode_Response } from '@api-new/authservice/HTTP_CP_SignIn_WithCode_Response';
import { HTTP_CP_Unsubscribe_WithCode_Request } from '@api-new/authservice/HTTP_CP_Unsubscribe_WithCode_Request';
import { HTTP_CP_Unsubscribe_WithCode_Response } from '@api-new/authservice/HTTP_CP_Unsubscribe_WithCode_Response';
import { HTTP_ULC_SignUp_Request } from '@api-new/authservice/HTTP_ULC_SignUp_Request';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class AuthApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_ImpersonateClientUser(request: HTTP_AP_ImpersonateClientUser_Request): Observable<HTTP_AP_ImpersonateClientUser_Response> {
    return this.httpClient.post(`/auth-service/ap/ImpersonateClientUser`, request);
  }

  HTTP_AP_ProvideAdvisorUserResetPasswordCode(request: HTTP_AP_ProvideAdvisorUserResetPasswordCode_Request): Observable<undefined> {
    return this.httpClient.post(`/auth-service/ap/ProvideAdvisorUserResetPasswordCode`, request);
  }

  HTTP_AP_ResetPassword_WithCode(request: HTTP_AP_ResetPassword_WithCode_Request): Observable<undefined> {
    return this.httpClient.post(`/auth-service/ap/ResetPassword/WithCode`, request);
  }

  HTTP_AP_SignIn_WithPassword(request: HTTP_AP_SignIn_WithPassword_Request): Observable<HTTP_AP_SignIn_WithPassword_Response> {
    return this.httpClient.post(`/auth-service/ap/SignIn/WithPassword`, request);
  }

  HTTP_AP_SignIn_WithProvider_Authorize(
    request: HTTP_AP_SignIn_WithProvider_Authorize_Request,
  ): Observable<HTTP_AP_SignIn_WithProvider_Authorize_Response> {
    return this.httpClient.post(`/auth-service/ap/SignIn/WithProvider/Authorize`, request);
  }

  HTTP_AP_SignIn_WithProvider_Initialize(
    request: HTTP_AP_SignIn_WithProvider_Initialize_Request,
  ): Observable<HTTP_AP_SignIn_WithProvider_Initialize_Response> {
    return this.httpClient.post(`/auth-service/ap/SignIn/WithProvider/Initialize`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_DeleteClientUser_WithCode(request: HTTP_CP_DeleteClientUser_WithCode_Request): Observable<undefined> {
    return this.httpClient.post(`/auth-service/cp/DeleteClientUser/WithCode`, request);
  }

  HTTP_CP_DeleteClientUserAccessToken(request: HTTP_CP_DeleteClientUserAccessToken_Request): Observable<undefined> {
    const { clientUserAccessTokenId, ...updatedRequest } = request;
    return this.httpClient.delete(`/auth-service/cp/client-user-access-tokens/${request.clientUserAccessTokenId}`, {
      params: updatedRequest,
    });
  }

  HTTP_CP_ListClientUserAccessTokens(request?: undefined): Observable<HTTP_CP_ListClientUserAccessTokens_Response> {
    return this.httpClient.get(`/auth-service/cp/client-user-access-tokens`, { params: request });
  }

  HTTP_CP_ListSignInCompanies(request: HTTP_CP_ListSignInCompanies_Request): Observable<HTTP_CP_ListSignInCompanies_Response> {
    return this.httpClient.post(`/auth-service/cp/ListSignInCompanies`, request);
  }

  HTTP_CP_ProvideClientUserDeleteCode(request?: undefined): Observable<undefined> {
    return this.httpClient.post(`/auth-service/cp/ProvideClientUserDeleteCode`, request);
  }

  HTTP_CP_ProvideClientUserSignInCode(request: HTTP_CP_ProvideClientUserSignInCode_Request): Observable<undefined> {
    return this.httpClient.post(`/auth-service/cp/ProvideClientUserSignInCode`, request);
  }

  HTTP_CP_SignIn_WithCode(request: HTTP_CP_SignIn_WithCode_Request): Observable<HTTP_CP_SignIn_WithCode_Response> {
    return this.httpClient.post(`/auth-service/cp/SignIn/WithCode`, request);
  }

  HTTP_CP_SignOut(request?: undefined): Observable<undefined> {
    return this.httpClient.post(`/auth-service/cp/SignOut`, request);
  }

  HTTP_CP_Unsubscribe_WithCode(request: HTTP_CP_Unsubscribe_WithCode_Request): Observable<HTTP_CP_Unsubscribe_WithCode_Response> {
    return this.httpClient.post(`/auth-service/cp/Unsubscribe/WithCode`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_ULC_SignUp(request: HTTP_ULC_SignUp_Request): Observable<undefined> {
    return this.httpClient.post(`/auth-service/ulc/SignUp`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
