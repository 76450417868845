import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, inject } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IconComponent } from '@shared-lib/components/icon/icon.component';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ButtonDirective } from 'primeng/button';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-terms-and-conditions-agreement',
  templateUrl: './terms-and-conditions-agreement.component.html',
  styleUrls: ['../../auth-theme.scss', './terms-and-conditions-agreement.component.scss'],
  imports: [AsyncPipe, CheckboxComponent, ReactiveFormsModule, ButtonDirective, IconComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsAgreementComponent implements OnInit {
  private readonly facade = inject(AuthFacadeService);
  private readonly router = inject(Router);

  readonly loading$ = this.facade.loading$;

  readonly privacyPolicyLink = 'https://www.dashly.com/terms/privacy';
  readonly tacLink = 'https://www.dashly.com/terms';
  acceptedTermsAndConditionsForm: UntypedFormGroup;

  ngOnInit(): void {
    this.acceptedTermsAndConditionsForm = new FormGroup({
      agreeTerms: new FormControl(null, [Validators.requiredTrue]),
    });
  }

  goToLoginPage(): void {
    this.facade.goToLoginPage();
  }

  agreeAndLogin(): void {
    if (this.acceptedTermsAndConditionsForm.invalid) {
      return;
    }
    this.facade.agreeToTermsAndConditions();
  }

  showWarnState(): boolean {
    const control = this.acceptedTermsAndConditionsForm.controls.agreeTerms;
    return control.touched && control.invalid;
  }
}
